<template>
  <LSection>
    <template #body>
      <div class="flex flex-col items-center justify-center mb-12">
        <div class="w-full">
          <TabNavigation :tabs="tabs" />
          <router-view name="educationTrainingTabs"></router-view>
        </div>
      </div>
    </template>
  </LSection>
</template>

<script>
import { ref } from "vue";
import LSection from "@/components/layout/LSection.vue";
import TabNavigation from "@/components/TabNavigation.vue";

export default {
  name: "Education&training",
  components: { LSection, TabNavigation },
  setup() {
    const tabs = ref([
      {
        name: "QTEdu Community",
        to: { name: "europeQTCommunity" },
        routeName: "europeQTCommunity",
      },
      {
        // name: "Resources From the QTEdu Community",
        name: "QTEdu Resources",
        to: { name: "resourcesQTCommunity" },
        routeName: "resourcesQTCommunity",
      },
      {
        name: "Competence Framework",
        to: { name: "competenceFramework" },
        routeName: "competenceFramework",
      },
      {
        name: "Quantum Masters",
        to: { name: "quantumMasters" },
        routeName: "quantumMasters",
      },
      {
        name: "Ongoing EU Projects",
        to: { name: "ongoingEUProjects" },
        routeName: "ongoingEUProjects",
      },
    ]);
    return {
      tabs,
    };
  },
};
</script>
